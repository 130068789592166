import isValidHttpUrl from "../../../helpers/isValidUrl";
import {
  exceptionUrlList,
  getRelativeUrl,
  otherSideCssClass,
} from "../../config";
import IPrimeMenuItem from "../models/IPrimeMenuItem";

/**
 * Find and close all child items for a given item
 * @param item Item who's children we want to close
 * @param excludeIds Array of menu item IDs to exclude from closing
 */
export const closeAllChildItems = (
  item: IPrimeMenuItem,
  excludeIds?: number[] | undefined
): IPrimeMenuItem => {
  const itemToExclude =
    excludeIds && excludeIds.filter((id) => item.id === id)[0];
  const exclude = itemToExclude !== undefined && itemToExclude === item.id;
  if (item.children) {
    return {
      ...item,
      children: item.children?.map((itm) => closeAllChildItems(itm)),
      open: exclude,
    };
  }
  return { ...item, open: false };
};

/**
 * Close all same level dropdowns for a given item
 * @param parentItem The parent item which encapsulates the current item
 * @param item The current item
 */
export const closeSameLevelDropdowns = (
  parentItem: IPrimeMenuItem,
  item: IPrimeMenuItem
): IPrimeMenuItem => {
  if (!parentItem.children) {
    return parentItem;
  }
  const isOfParent = parentItem.children?.some(
    (childItem) => childItem.id === item.id
  );
  return {
    ...parentItem,
    children: parentItem.children?.map((childItem) =>
      isOfParent
        ? closeAllChildItems(childItem, [item.id])
        : closeSameLevelDropdowns(childItem, item)
    ),
  };
};

/**
 *
 * @param elem HTMLElement that represent an `ul.other-side` element.
 * @param rootDropdown
 */
export const decideSubMenuPosition = (elem: Element, mobile: boolean): void => {
  if (elem && elem.getBoundingClientRect && !mobile) {
    const docW = window.innerWidth;
    const l = elem.getBoundingClientRect().left;
    const w = elem.getBoundingClientRect().width;

    const isEntirelyVisible = l + w <= docW;

    if (!isEntirelyVisible) {
      // There is not enough space, show the dropdown on the left.
      elem.classList.add(otherSideCssClass);
    }
  }
};

/**
 * Look for an item in the `items` parameter and change the open state.
 * @param id - The id to look for in the prime menu.
 * @param items - The list of items.
 * @param openState - Open or close the dropdown.
 * @param dontImpactOthers - If true, leave the other dropdown open. Used on mobile.
 */
export const toggleOpenState = (
  id: number,
  items: IPrimeMenuItem[],
  openState?: boolean,
  dontImpactOthers?: boolean
): IPrimeMenuItem[] => {
  return items.map((e: IPrimeMenuItem) => {
    if (e.id === id) {
      return {
        ...e,
        open: openState || !e.open,
      };
    }

    if (e.children) {
      return {
        ...e,
        children: toggleOpenState(id, e.children, openState, dontImpactOthers),
        open: dontImpactOthers ? e.open : false,
      };
    }
    return {
      ...e,
      open: false,
    };
  });
};

/**
 * Find open menus and calculate available space to show the menu on the correct side.
 */
export const findOpenMenus = (
  rootRef: HTMLDivElement,
  isMobile: boolean
): void => {
  const openItems = rootRef.querySelectorAll(".is-open > ul");
  const otherSideItems = rootRef.querySelectorAll(`.${otherSideCssClass}`);

  Array.from(otherSideItems).forEach((element) => {
    element.classList.remove(otherSideCssClass);
  });

  Array.from(openItems).forEach((element) => {
    decideSubMenuPosition(element, isMobile);
  });
};

export const matchesUrl = (locationUrl: string, itemUrl: string): boolean => {
  /**
   * Check if we are in a section that can hold subsections after
   * the main url part. These are listed in the exception rules.
   */
  const exceptionRule =
    exceptionUrlList.filter((url) => itemUrl.indexOf(url) !== -1) ?? undefined;
  if (exceptionRule && exceptionRule.length) {
    return locationUrl.indexOf(exceptionRule[0]) !== -1;
  } else {
    /**
     * If we are not in a subsection, highlight the necessary item
     */
    let result = false;

    // location and item URLs are identical
    if (itemUrl === locationUrl) {
      return true;
    }

    // add slash if URL is relative and does not start with a slash
    if (!isValidHttpUrl(itemUrl) && !itemUrl.startsWith("/")) {
      itemUrl = "/" + itemUrl;
    }

    // check URLs that are different from home URLs
    if (itemUrl !== "/" && itemUrl !== "/#" && itemUrl !== "/#/") {
      result =
        locationUrl.startsWith(itemUrl) ||
        locationUrl.startsWith(itemUrl + "/");
    }
    return result;
  }
};

const removePreviousActiveLinks = (items: IPrimeMenuItem[]): IPrimeMenuItem[] =>
  items.map(
    (item): IPrimeMenuItem => ({
      ...item,
      active: false,
      children: item.children
        ? removePreviousActiveLinks(item.children)
        : item.children,
      parentIds: undefined,
    })
  );

function activateMenuItem(
  items: IPrimeMenuItem[],
  currentUrl: string,
  parent?: IPrimeMenuItem
): { parent?: IPrimeMenuItem; items: IPrimeMenuItem[] } {
  const newItems = items.map((item) => {
    if (matchesUrl(currentUrl, item.url)) {
      if (parent) {
        parent.active = true;
      }
      item.active = true;
    }

    if (item.children?.length) {
      const menuItems = activateMenuItem(item.children, currentUrl, item);

      if (parent && menuItems.parent?.active) {
        parent.active = menuItems.parent.active;
      }
      item.children = menuItems.items;
    }

    return item;
  });

  if (parent) {
    parent.children = newItems;
  }

  return {
    parent,
    items: newItems,
  };
}

export function setActiveMenuItemTree(
  items: IPrimeMenuItem[],
  currentUrl: string
): IPrimeMenuItem[] {
  const result = activateMenuItem(items, currentUrl);
  return result.items;
}

/**
 * Find and set the current active link in the menu item list
 * @param items List of all menu items
 */
export const setActiveMenuItemTreeFromCurrentLocation = (
  items: IPrimeMenuItem[]
): IPrimeMenuItem[] => {
  const currentUrl = getRelativeUrl();
  const inactiveItems = removePreviousActiveLinks(items);
  return setActiveMenuItemTree(inactiveItems, currentUrl);
};
