/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */

if (!Element.prototype.matches) {
  Element.prototype.matches =
    (Element.prototype as any).msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const el = this;

    do {
      if (Element.prototype.matches.call(el, s)) {
        return el;
      }
      (el as any) = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
