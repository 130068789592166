/**
 * Available environment.
 */
export enum Environment {
  development = "development",
  homologation = "homologation",
  production = "production",
}

/**
 * Configuration properties.
 */
export interface IConfigEnvironment {
  primeEndpoint: string;
  primePulseEndpoint: string;
  menuApi: string;
  siteId: number;
  urlBase: string;
  useFakes: boolean;
}

/**
 * Configurations by environment.
 */
const config: Record<Environment, IConfigEnvironment> = {
  development: {
    primeEndpoint: "https://prime-dev.sgmarkets.com",
    primePulseEndpoint: "https://prime-pulse-dev.sgmarkets.com",
    menuApi:
      "https://api-s-uat.sgmarkets.com/services/prime/utility/v1/sgmprimeutil/menus",
    siteId: 877540296,
    urlBase: "https://sg-analytics-uat.fr.world.socgen/piwik/",
    useFakes: false,
  },
  homologation: {
    primeEndpoint: "https://prime-uat.sgmarkets.com",
    primePulseEndpoint: "https://prime-pulse-uat.sgmarkets.com",
    menuApi:
      "https://api-s-uat.sgmarkets.com/services/prime/utility/v1/sgmprimeutil/menus",
    siteId: 877540296,
    urlBase: "https://sg-analytics-uat.fr.world.socgen/piwik/",
    useFakes: false,
  },
  production: {
    primeEndpoint: "https://prime.sgmarkets.com",
    primePulseEndpoint: "https://prime-pulse.sgmarkets.com",
    menuApi:
      "https://api-s.sgmarkets.com/services/prime/utility/v1/sgmprimeutil/menus",
    siteId: 584,
    urlBase: "https://sg-analytics-uat.fr.world.socgen/piwik/",
    useFakes: false,
  },
};

/**
 * Get the configuration based on the environment.
 */
export const getConfig = (): IConfigEnvironment => {
  const { environment } = window.SGWTWidgetConfiguration;
  return config[environment as Environment];
};

/**
 * Get the `menuApi` based on the environment.
 * For the moment while new menu api is not yet implemented, fallback to the old menu api
 */
export const getMenuApi = (): string => {
  return getConfig().menuApi;
};

/**
 * Get the `siteId` of matomo based on the environment.
 */
export const getSiteId = (): number => {
  return getConfig().siteId;
};

/**
 * Get the `urlBase` of matomo based on the environment.
 */
export const getUrlBase = (): string => {
  return getConfig().urlBase;
};

/**
 * Get the `endpoint` based on the environment.
 */
export const getPrimeEndpoint = (): string => {
  return getConfig().primeEndpoint;
};

/**
 * Get the `endpoint` based on the environment.
 */
export const getPrimePulseEndpoint = (): string => {
  return getConfig().primePulseEndpoint;
};

/**
 * Gets the relative URL incliding the hashtag
 * @returns string
 */
export const getRelativeUrl = (): string => {
  return window.location.pathname + window.location.hash;
};

/**
 * Get the `useFakes` based on the environment.
 */
export const getUseFakes = (): boolean => {
  // return true;
  return getConfig().useFakes;
};

/**
 * Get the menus name to be tracked in matomo when clicking on the link
 */
export const getMenuNameToBeTrack = (): string[] => ["Home", "SGM Dashboard"];

/**
 * We should not show subsections for these items as they will
 * have separate sub menus on Pulse
 */
export const excludeSubsections = [
  "SGM Trades",
  "SGM Positions",
  "SGM Reports",
];

/**
 * Add exception urls here that might have subsections but should be
 * highlighted in the menu
 */
export const exceptionUrlList = ["/payment-services"];

export const otherSideCssClass = "other-side";

export default config;
