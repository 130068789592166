import { getMenuNameToBeTrack, getUseFakes } from "../../config";
import primeMenuData from "../../fakes/fake-pulse-menu";
import { getMenu } from "../api/menu";
import { convertAllToPrimeMenuItem } from "../mappers/primeMenuItemMapper";
import IPrimeMenuItem from "../models/IPrimeMenuItem";

/**
 * Default menu with the home link.
 */
export const defaultMenu: IPrimeMenuItem[] = [
  {
    displayName: "Home",
    hoverDescription: "Home",
    id: 0,
    name: "Home",
    url: "/",
  },
];

export function getDefaultMenu(): IPrimeMenuItem[] {
  return trackMenu(defaultMenu, getMenuNameToBeTrack());
}

function trackMenu(
  menus: IPrimeMenuItem[],
  menuNameToBeTrack: string[]
): IPrimeMenuItem[] {
  return menus.map((menu) => ({
    ...menu,
    onClickTrack: menuNameToBeTrack.indexOf(menu.name) > -1,
  }));
}

/**
 * Based on the current environment, return the menu.
 * If `useFakes` is true, use the mock data. And if it's false, call the API.
 */
export async function loadPrimeMenu(token?: string): Promise<IPrimeMenuItem[]> {
  if (getUseFakes()) {
    return [
      ...defaultMenu,
      ...convertAllToPrimeMenuItem(
        primeMenuData as unknown as IPrimeMenuItem[]
      ),
    ];
  } else {
    const response = await getMenu(token);
    const menus = [...defaultMenu, ...convertAllToPrimeMenuItem(response.data)];
    return trackMenu(menus, getMenuNameToBeTrack());
  }
}
