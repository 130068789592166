/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState } from "react";
import { getSiteId, getUrlBase } from "../config";
import {
  TrackEventCategory,
  TrackEventNavigationAction,
} from "../constants/trackEvent";
import "../polyfills/getClosest";
import axiosinterceptor from "./axiosinterceptor";
import {
  closeAllChildItems,
  closeSameLevelDropdowns,
  findOpenMenus,
  setActiveMenuItemTreeFromCurrentLocation,
  toggleOpenState,
} from "./helpers/menuHelper";
import MobileButton from "./MobileButton";
import IPrimeMenuItem from "./models/IPrimeMenuItem";
import { getDefaultMenu } from "./services/menu";
import { useWidgetConfiguration } from "@sg-widgets/react-core";
import { loadPrimeMenu } from "./services/menu";
import MatomoTracker from "@datapunt/matomo-tracker-js";
import axios from "axios";
import Menu from "./Menu";
import useHasChange from "./helpers/useHasChange";

/**
 * Include the interceptor to check
 * for IMPERSONATEE header presence
 */
axiosinterceptor(axios);

/**
 * PrimeMenu props interface.
 */
interface Props {
  breakpoint: number;
  truncateOffset: number;
}

/**
 * PrimeMenu Component
 */
const BUS_ACCESS_TOKEN = "sg-connect.access-token";

const PrimeMenu: React.FC<Props> = ({ breakpoint, truncateOffset }: Props) => {
  const [loaded, setLoaded] = useState(false);
  const [mobile, setMobile] = useState(
    window.innerWidth < breakpoint ? true : false
  );
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [originalPrimeMenu, setOriginalPrimeMenu] = useState(getDefaultMenu());
  const [primeMenu, setPrimeMenu] = useState(getDefaultMenu());
  const [widthsRegistered, setWidthsRegistered] = useState(false);
  const [loadMenuCalled, setLoadMenuCalled] = useState(false);

  const rootRef = useRef<HTMLDivElement>(null);
  const widgetConfiguration = useWidgetConfiguration();

  let resizeTimeoutId = 0;

  const tracker = new MatomoTracker({
    siteId: getSiteId(),
    urlBase: getUrlBase(),
  });

  /**
   * Bind events, load the menu and toggle the mobile class.
   */
  let subscription: unknown;

  const onNavigate = (item: IPrimeMenuItem): void => {
    if (item.onClickTrack) {
      tracker.trackEvent({
        action: TrackEventNavigationAction.Click,
        category: TrackEventCategory.Navigation,
        name: item.displayName,
      });
    }
  };

  /**
   * If the width of all items is too wide, we should truncate the menu
   * by moving the items that do not fit under the "More" option
   */
  const truncateMenu = (menuItems: IPrimeMenuItem[]): void => {
    const rootElement = rootRef.current;

    if (!loaded || !rootElement || mobile) {
      return;
    }

    // Width offset to take into account
    let aggregateElementWidth = 0;
    let maxElementCount: number | undefined;
    // Width of the widget container
    const containerWidth = (
      rootElement as HTMLDivElement
    ).getBoundingClientRect().width;

    for (let i = 0; i < menuItems.length; i++) {
      aggregateElementWidth += menuItems[i].width ?? 0;
      if (aggregateElementWidth + truncateOffset >= containerWidth) {
        maxElementCount = i - 1;
        break;
      }
    }

    // If the width of all items is too wide, we should truncate the menu
    if (aggregateElementWidth + truncateOffset >= containerWidth && !mobile) {
      const newPrimeMenu = menuItems.slice(0, maxElementCount);
      // Put all other menu items under "More" section
      newPrimeMenu.push({
        children: menuItems.slice(maxElementCount, menuItems.length),
        displayName: "More",
        hoverDescription: "More",
        id: 9999999,
        name: "More",
        open: false,
        url: "#",
      });

      setPrimeMenu(setActiveMenuItemTreeFromCurrentLocation(newPrimeMenu));
    } else {
      // Menu is wide enough to fit the viewport, display all the menu items
      setPrimeMenu(setActiveMenuItemTreeFromCurrentLocation(menuItems));
    }
  };

  /**
   * When loading the widget for the first time (on the desktop viewport)
   * we need to messure the width of each parent menu item to determine
   * whether the menu size is too large and we need to truncate the menu
   */
  const registerMenuItemWidths = (): IPrimeMenuItem[] => {
    if (widthsRegistered || mobile || !loaded) {
      return [];
    }
    const primeMenuWithWidths = primeMenu.map((item) => {
      return {
        ...item,
        width: rootRef.current
          ? rootRef.current
              .querySelector(`li[id="pm-${item.id}"]`)
              ?.getBoundingClientRect().width
          : 100,
      };
    });

    setOriginalPrimeMenu(primeMenuWithWidths);
    setPrimeMenu(primeMenuWithWidths);
    setWidthsRegistered(true);

    return primeMenuWithWidths;
  };

  /**
   * Subscribe to widget bus.
   * @param success - Callback function with token parameter
   */
  const subscribeToBusAccessToken = (
    success?: (token?: string) => void
  ): void => {
    subscription = widgetConfiguration.bus.subscribe(
      BUS_ACCESS_TOKEN,
      (token?: string) => {
        if (success) {
          success(token);
        }
      }
    );
  };

  /**
   * Handle click on the mobile button.
   */
  const handleMobileButtonClick = (): void => {
    setMobileMenuOpen((state) => !state);
  };

  /**
   * Conditions to show / hide the menu
   */
  const isMenuVisible = (): boolean => {
    return !mobile || (mobile && mobileMenuOpen);
  };

  /**
   * Set the menu on the component state.
   */
  const loadMenu = async (token?: string): Promise<void> => {
    if (!loaded && !loadMenuCalled) {
      try {
        setLoadMenuCalled(true);
        const originalMenuItems = await loadPrimeMenu(token);
        const menuItems =
          setActiveMenuItemTreeFromCurrentLocation(originalMenuItems);
        setPrimeMenu(menuItems);
        setOriginalPrimeMenu(menuItems);
        setLoaded(true);
      } catch (e) {
        setLoaded(true);
      }
    }
  };

  /**
   * Based on the breakpoint, show the mobile version.
   */
  const toggleMobileClass = (): void => {
    if (window.innerWidth < breakpoint) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  /**
   * Look for the item in the `state.primeMenu` and toggle the dropdown menu.
   * @param id - The id to look for in the prime menu.
   * @param openState - Open or close the dropdown.
   * @param dontImpactOthers - If true, leave the other dropdown open. Used on mobile.
   */
  const toggleDropdown = (
    id: number,
    openState?: boolean,
    dontImpactOthers?: boolean
  ): void => {
    setPrimeMenu(toggleOpenState(id, primeMenu, openState, dontImpactOthers));
  };

  useEffect(() => {
    if (!rootRef.current) {
      return;
    }
    findOpenMenus(rootRef.current as HTMLDivElement, mobile);
  }, [mobile, primeMenu, rootRef]);

  /**
   * Close all dropdowns. Also close the menu if on mobile.
   * Since the page might not reload (an SPA for instance),
   * make sure all dropdown are closed when the user clicks a link.
   */
  const closeAllDropdowns = (): void => {
    if (primeMenu) {
      setMobileMenuOpen(false);
      setPrimeMenu(primeMenu.map((item) => closeAllChildItems(item)));
    }
  };

  /**
   * Perform necessary actions on the same menu level items
   * 1. When clicking on one dropdown, open it and close the others
   * 2. Decide on which side to position the opened dropdown
   * @param item The current item
   */
  const handleSameLevelDropdowns = (item: IPrimeMenuItem): void => {
    if (primeMenu) {
      setMobileMenuOpen(true);
      setPrimeMenu(
        primeMenu.map((parentItem) => closeSameLevelDropdowns(parentItem, item))
      );
    }
  };

  /**
   * On resize event do the following:
   * 1. If the viewport changes from "mobile" to "desktop", close all dropdowns
   * 2. Add/remove the mobile class attribute
   * 3. See if there is a need to truncate the menu with the "More" option
   */
  const handleResize = (): void => {
    clearTimeout(resizeTimeoutId);
    resizeTimeoutId = setTimeout(
      () => {
        // desktop version
        if (window.innerWidth >= breakpoint) {
          closeAllDropdowns();
        }

        toggleMobileClass();
        truncateMenu(originalPrimeMenu);
      },
      50,
      {}
    );
  };

  /**
   * On each location change event,
   * find and set the new link to active
   */
  const handleLocationChange = (): void => {
    // First reset the previous active menu item
    setOriginalPrimeMenu(
      setActiveMenuItemTreeFromCurrentLocation(originalPrimeMenu)
    );

    setPrimeMenu(setActiveMenuItemTreeFromCurrentLocation(primeMenu));
  };

  /**
   * When the user clicks anywhere on the document, close all dropdown menus.
   */
  const closeAllDropdownsOnDocumentClick = (e: MouseEvent): void => {
    const primeMenuTag = "prime-menu";
    const target = e.target as HTMLElement;
    let isElementOutsideWidget = target && target.localName !== primeMenuTag;
    // Check if IE
    const ua = window.navigator.userAgent;
    const isIE = /MSIE|Trident|Edge\//.test(ua);

    if (isIE) {
      isElementOutsideWidget =
        // This check is needed in Internet Explorer
        target && target.closest("prime-menu") === null;
    }
    if (isElementOutsideWidget && primeMenu) {
      closeAllDropdowns();
    }
  };

  const hasLoadedChanged = useHasChange(loaded);
  const hasMobileChanged = useHasChange(mobile);

  useEffect(() => {
    /**
     * If the menu is just loaded or the viewport has changed
     * from mobile to desktop, we need to reevaluate the menu width
     * and determine whether we need to truncate the menu
     */
    if (hasLoadedChanged || hasMobileChanged) {
      const primeMenu = registerMenuItemWidths();
      truncateMenu(primeMenu);
    }
  });

  useEffect(() => {
    const unsubscribeToBusAccessToken = (): void => {
      if (subscription) {
        widgetConfiguration.bus.unsubscribe(subscription);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        subscription = null;
      }
    };

    subscribeToBusAccessToken(async (token) => {
      await loadMenu(token);
    });
    document.addEventListener(
      "mousedown",
      closeAllDropdownsOnDocumentClick,
      false
    );
    window.addEventListener("resize", handleResize, false);
    window.addEventListener("locationchange", handleLocationChange, false);
    window.addEventListener("hashchange", handleLocationChange, false);

    toggleMobileClass();
    return function cleanUp() {
      document.removeEventListener(
        "mousedown",
        closeAllDropdownsOnDocumentClick,
        false
      );
      window.removeEventListener("resize", handleResize, false);
      window.removeEventListener("locationchange", handleLocationChange, false);
      window.removeEventListener("hashchange", handleLocationChange, false);

      unsubscribeToBusAccessToken();
    };
  }, [
    closeAllDropdownsOnDocumentClick,
    handleLocationChange,
    handleResize,
    loadMenu,
    subscribeToBusAccessToken,
    toggleMobileClass,
  ]);

  if (!loaded) {
    return <div></div>;
  }

  return (
    <div
      id="prime-menu-widget-root"
      ref={rootRef}
      className={`${mobile ? "mobile" : ""}`}
      style={{
        display: "block",
        width: "100%",
      }}
    >
      <MobileButton isOpen={mobileMenuOpen} onClick={handleMobileButtonClick} />
      {isMenuVisible() && primeMenu.length ? (
        <Menu
          items={primeMenu}
          mobile={mobile}
          toggleDropdown={toggleDropdown}
          closeAllDropdowns={closeAllDropdowns}
          handleSameLevelDropdowns={handleSameLevelDropdowns}
          onNavigate={onNavigate}
        />
      ) : null}
    </div>
  );
};

export default PrimeMenu;
