import React, { PropsWithChildren } from "react";

export interface Props {
  styles?: { toString: () => string } | string;
}

/**
 * Inject CSS in your component tree
 * Pass-in a stringifiable object as a `styles` prop to have it injected in a `<style>` tag.
 */
const WithCss: React.FC<PropsWithChildren<Props>> = ({ styles, children }) => {
  const styleContent = styles ? styles.toString() : null;
  return (
    <>
      {styleContent === null ? null : <style>{styleContent}</style>}
      {children}
    </>
  );
};

export default WithCss;
