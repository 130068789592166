import { AxiosInstance } from "axios";

/**
 * this key is shared SGM.Prime.WebApp and pulse-ui
 */
const SESSION_STORAGE_USER_KEY = "SGM.Prime:impersonateeUser";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getImpersonateeUserFromSessionStorage = () => {
  const user = sessionStorage.getItem(SESSION_STORAGE_USER_KEY);
  if (user) {
    return JSON.parse(user);
  }
  return undefined;
};

export default function (axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use((request) => {
    // If there is an impersonatee defined in the session storage, add the impersonatee header
    const impersonatee = getImpersonateeUserFromSessionStorage()?.email;
    if (impersonatee && impersonatee.length > 0) {
      request.headers.IMPERSONATEE = impersonatee;
    }
    return request;
  });

  return axiosInstance;
}
