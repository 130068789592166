import IPrimeApiItem from "../components/models/IPrimeApiItem";

const fakePulseMenu: IPrimeApiItem[] = [
  {
    id: 1,
    name: "AAA",
    displayName: "AAA",
    url: "/index.htm/#aaa",
    hoverDescription: "AAA",
  },
  {
    id: 2,
    name: "BBB",
    displayName: "BBB",
    url: "/index.htm/#bbb",
    hoverDescription: "BBB",
  },
  {
    id: 3,
    name: "CCC",
    displayName: "CCC",
    url: "/index.htm/#ccc",
    hoverDescription: "CCC",
    children: [
      {
        id: 33,
        name: "Second level C",
        displayName: "Second level C",
        url: "/index.htm/#secondlevelc",
        children: [
          {
            id: 333,
            name: "Third level C",
            displayName: "Third level C",
            url: "/index.htm/#thirdlevelc",
            children: [
              {
                id: 3333,
                name: "Fourth level C",
                displayName: "Fourth level C",
                url: "/index.htm/#fourthlevelc",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: "DDD",
    displayName: "DDD",
    url: "/index.htm/#ddd",
    hoverDescription: "DDD",
  },
  {
    id: 1878.1,
    name: "P3 LD Balance",
    displayName: "Balance",
    url: "#/balance",
    hoverDescription: "Balance",
  },
  {
    id: 1855.0,
    name: "P3 Futures",
    displayName: "Futures",
    url: "#",
    children: [
      {
        id: 1876.0,
        name: "P3 LD Transaction",
        displayName: "Trade",
        url: "#/transactionld",
        hoverDescription: "Trade",
      },
      {
        id: 1877.0,
        name: "P3 LD Position",
        displayName: "Position & Valuation",
        url: "#/positionld",
        hoverDescription: "Position & Valuation",
      },
      {
        id: 1879.0,
        name: "P3 LD Trading Event",
        displayName: "Trading Event",
        url: "#/tradingEvent",
        hoverDescription: "Trading Event",
      },
    ],
    hoverDescription: "Futures",
  },
  {
    id: 1856.0,
    name: "P3 Equity",
    displayName: "Equity",
    url: "#",
    children: [
      {
        id: 1881.0,
        name: "P3 EQT STOCKS AND OPTIONS MENU",
        displayName: "Stocks/Options",
        url: "#",
        children: [
          {
            id: 2409.0,
            name: "P3 EQT STOCKS AND OPTIONS",
            displayName: "Trades",
            url: "#",
            hoverDescription: "Trades",
          },
          {
            id: 1883.0,
            name: "P3 EQT POSITION",
            displayName: "Positions & Valuation",
            url: "#",
            hoverDescription: "Positions & Valuation",
          },
        ],
        hoverDescription: "Stocks/Options",
      },
      {
        id: 2408.0,
        name: "P3 EQT SYNTHETICS",
        displayName: "Synthetic",
        url: "#",
        children: [
          {
            id: 1882.0,
            name: "P3 EQT CFD",
            displayName: "CFD Trades",
            url: "#",
            hoverDescription: "CFD Trades",
          },
        ],
        hoverDescription: "Synthetic",
      },
      {
        id: 16963.0,
        name: "P3 EQT QES BOOKINGS MENU",
        displayName: "Allocations",
        url: "#",
        hoverDescription: "Allocations",
      },
    ],
    hoverDescription: "Equity",
  },
  {
    id: 1857.0,
    name: "P3 Foreign Exchange",
    displayName: "FX",
    url: "#",
    children: [
      {
        id: 1885.0,
        name: "P3 FX Transaction",
        displayName: "Trade",
        url: "#/transactionfx",
        hoverDescription: "Trade",
      },
      {
        id: 1886.0,
        name: "P3 FX Position",
        displayName: "Position & Valuation",
        url: "#/positionfx",
        hoverDescription: "Position & Valuation",
      },
    ],
    hoverDescription: "FX",
  },
  {
    id: 1858.0,
    name: "P3 OTC",
    displayName: "OTC",
    url: "#",
    children: [
      {
        id: 1887.0,
        name: "P3 OTC Bilateral",
        displayName: "Bilateral",
        url: "#",
        children: [
          {
            id: 1889.0,
            name: "P3 OTC BI Trade Agency",
            displayName: "Trade Agency",
            url: "#/bitradeagencyotc",
            hoverDescription: "Trade Agency",
          },
        ],
        hoverDescription: "Bilateral",
      },
      {
        id: 1888.0,
        name: "P3 OTC Cleared",
        displayName: "Cleared",
        url: "#",
        children: [
          {
            id: 1892.0,
            name: "P3 OTC CL Transaction",
            displayName: "Trade & Status",
            url: "#/cltransactionotc",
            hoverDescription: "Trade & Status",
          },
          {
            id: 1893.0,
            name: "P3 OTC CL Position",
            displayName: "Position & Valuation",
            url: "#/clpositionotc",
            hoverDescription: "Position & Valuation",
          },
        ],
        hoverDescription: "Cleared",
      },
    ],
    hoverDescription: "OTC",
  },
  {
    id: 1859.0,
    name: "P3 Repo",
    displayName: "Repo",
    url: "#",
    children: [
      {
        id: 1928.0,
        name: "P3 REPO BLOT Transaction",
        displayName: "Trades & Status",
        url: "#/firepotrades",
        hoverDescription: "Trades & Status",
      },
      {
        id: 1929.0,
        name: "P3 REPO BLOT Position",
        displayName: "Positions & Valuation",
        url: "#/firepopositions",
        hoverDescription: "Positions & Valuation",
      },
    ],
    hoverDescription: "Repo",
  },
  {
    id: 1860.0,
    name: "P3 Fixed Income",
    displayName: "FI",
    url: "#",
    children: [
      {
        id: 1920.0,
        name: "P3 FI BLOT Trade Status",
        displayName: "Trades & Status",
        url: "#/transactionfi",
        hoverDescription: "Trades & Status",
      },
      {
        id: 1921.0,
        name: "P3 FI BLOT Position",
        displayName: "Positions & Valuation",
        url: "#/positionfi",
        hoverDescription: "Positions & Valuation",
      },
      {
        id: 1922.0,
        name: "P3 FI BLOT Cash Movements",
        displayName: "Cash Movements",
        url: "#/cashmovements",
        hoverDescription: "Cash Movements",
      },
      {
        id: 1935.0,
        name: "P3 FI BLOT Settlement Date Position",
        displayName: "Settle Date Position",
        url: "#/fisettledatepos",
        hoverDescription: "Settle Date Position",
      },
      {
        id: 19109.0,
        name: "test111",
        displayName: "test111",
        url: "#",
      },
    ],
    hoverDescription: "FI",
  },
  {
    id: 1861.0,
    name: "P3 Reporting",
    displayName: "Reporting",
    url: "#",
    children: [
      {
        id: 1898.0,
        name: "P3 RPT Queries",
        displayName: "Queries",
        url: "#/reports/queries",
        hoverDescription: "Queries",
      },
      {
        id: 1897.0,
        name: "Test",
        displayName: "Test avec slash",
        url: "/pulse/#/reports",
        hoverDescription: "Test",
      },
      {
        id: 1896.0,
        name: "Test",
        displayName: "Test sans slash",
        url: "pulse/#/reportbuilder",
        hoverDescription: "Test",
      },
      {
        id: 1899.0,
        name: "P3 RPT Reports",
        displayName: "Reports",
        url: "#/reports",
        hoverDescription: "Reports",
      },
      {
        id: 1900.0,
        name: "P3 RPT Report Builder",
        displayName: "Report Builder",
        url: "#/reportBuilder",
        hoverDescription: "Report Builder",
      },
      {
        id: 1901.0,
        name: "P3 RPT Scheduling",
        displayName: "Scheduling",
        url: "#/schedules/definition",
        hoverDescription: "Scheduling",
      },
      {
        id: 1902.0,
        name: "P3 RPT File Explorer",
        displayName: "File Explorer",
        url: "#/filemgmt",
        hoverDescription: "File Explorer",
      },
      {
        id: 1903.0,
        name: "P3 RPT Statements",
        displayName: "Statements",
        url: "#",
        children: [
          {
            id: 1939.0,
            name: "P3 Daily Stmt",
            displayName: "GMI Statement Daily",
            url: "#/stmt/DAILY",
            hoverDescription: "GMI Statement Daily",
          },
          {
            id: 1940.0,
            name: "P3 Monthly Stmt",
            displayName: "GMI Statement Monthly",
            url: "#/stmt/MONTHLY",
            hoverDescription: "GMI Statement Monthly",
          },
          {
            id: 1941.0,
            name: "P3 Monthly GST Stmt",
            displayName: "GST Statement (Singapore) Monthly",
            url: "#/stmt/GST",
            hoverDescription: "GST Statement (Singapore) Monthly",
          },
          {
            id: 2605.0,
            name: "P3 Confirmations",
            displayName: "Securities Confirmations",
            url: "#/stmt/CONFIRMS",
            hoverDescription: "Securities Confirmations",
          },
          {
            id: 4437.0,
            name: "P3 Securities Daily",
            displayName: "Securities Statements Daily",
            url: "#/stmt/SECURITIES_DAILY",
            hoverDescription: "Securities Statements Daily",
          },
          {
            id: 18358.0,
            name: "P3 Statements Reader",
            displayName: "Statements Reader",
            url: "#/stmtReader",
            hoverDescription: "Statements Reader",
          },
        ],
        hoverDescription: "Statements",
      },
    ],
    hoverDescription: "Reporting",
  },
  {
    id: 1871.0,
    name: "P3 Matching",
    displayName: "Matching",
    url: "#",
    children: [
      {
        id: 18374.0,
        name: "P3 Duco Matching",
        displayName: "Reconciliation",
        url: "#/ducoReconciliation",
        hoverDescription: "Reconciliation",
      },
    ],
    hoverDescription: "Matching",
  },
  {
    id: 1875.0,
    name: "P3 CMT",
    displayName: "Customer Mgmt",
    url: "#/admin/recent-actions",
    hoverDescription: "Customer Mgmt",
  },
  {
    id: 2216.0,
    name: "P3 System Admin",
    displayName: "Admin",
    url: "#",
    children: [
      {
        id: 2346.0,
        name: "P3 Admin SAG",
        displayName: "SAG",
        url: "#/sag",
        hoverDescription: "SAG",
      },
      {
        id: 2348.0,
        name: "P3 Modules",
        displayName: "Menus",
        url: "#/modules",
        hoverDescription: "Menus",
      },
      {
        id: 2349.0,
        name: "P3 Permissions",
        displayName: "Permissions",
        url: "#/permissions",
        hoverDescription: "Permissions",
      },
      {
        id: 4438.0,
        name: "P3 Admin User Onboarding",
        displayName: "User Onboarding",
        url: "#/useronboarding",
        hoverDescription: "User Onboarding",
      },
      {
        id: 17042.0,
        name: "P3 Workflow Parent",
        displayName: "Workflow",
        url: "#",
        children: [
          {
            id: 17044.0,
            name: "P3 Workflow Config",
            displayName: "Workflow Config",
            url: "#/workflow/config",
            hoverDescription: "Workflow Config",
          },
        ],
        hoverDescription: "Workflow",
      },
    ],
    hoverDescription: "Admin",
  },
  {
    id: 17123.0,
    name: "P3 Workflow Requests",
    displayName: "Requests",
    url: "#",
    children: [
      {
        id: 17673.0,
        name: "P3 Workflow",
        displayName: "All Requests",
        url: "#/workflow/approval",
        hoverDescription: "All Requests",
      },
    ],
    hoverDescription: "Requests",
  },
  {
    id: 18230.0,
    name: "P3 Audit Admin",
    displayName: "Audit",
    url: "#/audit/admin",
    hoverDescription: "Audit",
  },
  {
    id: 1868.0,
    name: "P3 Links",
    displayName: "Links",
    url: "#",
    children: [
      {
        id: 1912.0,
        name: "P3 LINKS Trading",
        displayName: "Trading, FX and OTC",
        url: "#",
        children: [
          {
            id: 1942.0,
            name: "P3 Fidessa Link",
            displayName: "Fidessa Setup Information",
            url: "#/fidessa",
            hoverDescription: "Fidessa Setup Information",
          },
          {
            id: 1963.0,
            name: "P3 Markitwire Link",
            displayName: "Markitwire Client Link",
            url: "https://www.markit.com/product/markitserv",
            hoverDescription: "Markitwire Client Link",
          },
          {
            id: 1964.0,
            name: "P3 TRM Link",
            displayName: "TRM Client Link",
            url: "https://newedgetpl.traiana.com/",
            hoverDescription: "TRM Client Link",
          },
        ],
        hoverDescription: "Trading, FX and OTC",
      },
      {
        id: 1913.0,
        name: "P3 LINKS Useful Info",
        displayName: "Useful Info",
        url: "#",
        children: [
          {
            id: 2428.0,
            name: "P3 SPAN Parameters",
            displayName: "SPAN Parameters",
            url: "http://www.cmegroup.com/clearing/risk-management/",
            hoverDescription: "SPAN Parameters",
          },
          {
            id: 2431.0,
            name: "P3 Latest Newedge Financial Report",
            displayName: "Latest Newedge Financial Report",
            url: "http://www.newedge.com/en/about/financial-report/",
            hoverDescription: "Latest Newedge Financial Report",
          },
          {
            id: 2427.0,
            name: "P3 Give-In Instructions Deadlines",
            displayName: "Give-In Instructions/Deadlines",
            url: "#/ssiview?file=Give-In_Instructions_asOfOct2015",
            hoverDescription: "Give-In Instructions/Deadlines",
          },
          {
            id: 2432.0,
            name: "P3 Equity Options Exercise Procedures",
            displayName: "Equity Options Exercise Procedures",
            url: "#/equityOpt",
            hoverDescription: "Equity Options Exercise Procedures",
          },
        ],
        hoverDescription: "Useful Info",
      },
      {
        id: 1914.0,
        name: "P3 LINKS Help",
        displayName: "Help",
        url: "#",
        children: [
          {
            id: 18173.0,
            name: "P3 Disclaimers",
            displayName: "Disclaimers",
            url: "#",
            hoverDescription: "Disclaimers",
          },
          {
            id: 1966.0,
            name: "P3 Pulse User Guide",
            displayName: "Pulse User Guide",
            url: "#",
            hoverDescription: "User Guide",
          },
          {
            id: 17748.0,
            name: "P3 Pulse Video Tutorials",
            displayName: "Pulse Video Tutorials",
            url: "#",
            hoverDescription: "Video Tutorials",
          },
          {
            id: 18174.0,
            name: "P3 Technical Requirements",
            displayName: "Technical Requirements",
            url: "#",
            hoverDescription: "Technical Requirements",
          },
          {
            id: 18175.0,
            name: "P3 On-Boarding",
            displayName: "On-Boarding",
            url: "#",
            hoverDescription: "On-Boarding",
          },
          {
            id: 18488.0,
            name: "P3 Web Safety",
            displayName: "Web Safety",
            url: "#",
            hoverDescription: "Web Safety",
          },
        ],
        hoverDescription: "Help",
      },
      {
        id: 1915.0,
        name: "P3 LINKS Settlement Instructions",
        displayName: "Settlement Instructions",
        url: "#",
        children: [
          {
            id: 19009.0,
            name: "P3 SGSHK SSI - Cash",
            displayName: "SGSHK SSI - Cash",
            url: "#",
            hoverDescription: "SGSHK SSI - Cash",
          },
          {
            id: 19010.0,
            name: "P3 SGSHK SSI PB Equities",
            displayName: "SGSHK SSI PB Equities",
            url: "#",
            hoverDescription: "SGSHK SSI PB Equities",
          },
          {
            id: 19011.0,
            name: "P3 SGSJ SSI - CASH",
            displayName: "SGSJ SSI - CASH",
            url: "#",
            hoverDescription: "SGSJ SSI - CASH",
          },
          {
            id: 19012.0,
            name: "P3 SGSJ SSI - PB",
            displayName: "SGSJ SSI - PB",
            url: "#",
            hoverDescription: "SGSJ SSI - PB",
          },
          {
            id: 1969.0,
            name: "P3 SG Newedge UK Ltd",
            displayName: "Societe Generale International Limited (SGIL)",
            url: "#",
            children: [
              {
                id: 17341.0,
                name: "P3 SGIL Cash SSI",
                displayName: "SGIL Non-Segregated Instructions",
                url: "#/cash-ssi",
                hoverDescription: "SGIL Non-Segregated Instructions",
              },
              {
                id: 18024.0,
                name: "P3 SGIL Collateral SSI",
                displayName: "SGIL Collateral SSI",
                url: "#",
                hoverDescription: "SGIL Collateral SSI",
              },
              {
                id: 18025.0,
                name: "P3 SGIL PB SSI",
                displayName: "SGIL PB SSI",
                url: "#",
                hoverDescription: "SGIL PB SSI",
              },
            ],
            hoverDescription: "Societe Generale International Limited (SGIL)",
          },
          {
            id: 19013.0,
            name: "P3 SGAS Collateral SSI",
            displayName: "SGAS Collateral SSI",
            url: "#",
            hoverDescription: "SGAS Collateral SSI",
          },
          {
            id: 1970.0,
            name: "P3 SG Americas Securities, LLC (30.7 Secured Accounts)",
            displayName: "SG Americas Securities, LLC (30.7 Secured Accounts)",
            url: "#",
            hoverDescription:
              "SG Americas Securities, LLC (30.7 Secured Accounts)",
          },
          {
            id: 1971.0,
            name: "P3 SG Americas Securities, LLC (Segregated Accounts)",
            displayName: "SG Americas Securities, LLC (Segregated Accounts)",
            url: "#",
            hoverDescription:
              "SG Americas Securities, LLC (Segregated Accounts)",
          },
        ],
        hoverDescription: "Settlement Instructions",
      },
    ],
    hoverDescription: "Links",
  },
];

export default fakePulseMenu;
