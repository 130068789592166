import { AxiosInstance } from "axios";
import Cookies from "js-cookie";

/**
 * this key is shared SGM.Prime.WebApp and pulse-ui
 */
const COOKIE_NAVIGATE_AS_KEY = "sgm-prime-navigate-as";
const getEnvironment = (): string => {
  const host = window.location.origin;

  if (
    host.indexOf("prime-pulse-dev.sgmarkets.com") !== -1 ||
    host.indexOf("prime-dev.sgmarkets.com") !== -1
  ) {
    return "-dev";
  }

  if (
    host.indexOf("prime-pulse-uat.sgmarkets.com") !== -1 ||
    host.indexOf("prime-uat.sgmarkets.com") !== -1
  ) {
    return "-uat";
  }

  return "";
};
const getCookieName = (): string => {
  return `${COOKIE_NAVIGATE_AS_KEY}${getEnvironment()}`;
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getImpersonateeUserFromSessionStorage = () => {
  const user = Cookies.get(getCookieName());
  if (user) {
    return JSON.parse(user);
  }
  return undefined;
};

export default function (axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use((request) => {
    // If there is an impersonatee defined in the session storage, add the impersonatee header
    const impersonatee = getImpersonateeUserFromSessionStorage()?.email;
    if (impersonatee && impersonatee.length > 0) {
      request.headers.IMPERSONATEE = impersonatee;
    }
    return request;
  });

  return axiosInstance;
}
