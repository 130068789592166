import axios, { AxiosResponse } from "axios";
import { getMenuApi } from "../../config";
import IPrimeApiItem from "../models/IPrimeApiItem";

/**
 * API call to request the menu.
 */
export const getMenu = (
  token?: string
): Promise<AxiosResponse<IPrimeApiItem[]>> => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.get<IPrimeApiItem[]>(getMenuApi(), config);
};
