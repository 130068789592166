import React from "react";
import Arrow from "./Arrow";

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

const MobileButton: React.FC<Props> = ({ isOpen, onClick }) => {
  const className = `prime-menu-bar-menu-toggle ${isOpen ? "active" : ""}`;

  return (
    <button className={className} onClick={onClick}>
      {"Menu"}

      <Arrow isVisible={true} showCaret={true} />
    </button>
  );
};

export default MobileButton;
