/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { isPulseUrl, makeRelativeUrlAbsolute } from "../../helpers/url";
import {
  excludeSubsections,
  getConfig,
  getPrimeEndpoint,
  getPrimePulseEndpoint,
} from "../config";
import Arrow from "./Arrow";
import IPrimeMenuItem from "./models/IPrimeMenuItem";

/**
 * Menu props interface.
 */
interface Props {
  parentItem?: IPrimeMenuItem;
  mobile: boolean;
  items: IPrimeMenuItem[];
  level?: number;
  toggleDropdown: (
    id: number,
    openState?: boolean,
    dontImpactOthers?: boolean
  ) => void;
  closeAllDropdowns: (excludeIds?: number[]) => void;
  handleSameLevelDropdowns: (item: IPrimeMenuItem) => void;
  onNavigate(item: IPrimeMenuItem): void;
}

/**
 * Menu that renders all items recursively.
 */
const Menu: React.FC<Props> = ({
  parentItem,
  mobile,
  items,
  level = 0,
  toggleDropdown,
  closeAllDropdowns,
  handleSameLevelDropdowns,
  onNavigate,
}) => {
  const showSubItems = (item: IPrimeMenuItem): boolean => {
    return (
      excludeSubsections.filter(
        (subsectionName) => item.name.indexOf(subsectionName) !== -1
      ).length === 0
    );
  };

  /**
   * Handle click on the <a /> links. If there is children, open a dropdown menu.
   */
  const handleClick = (e: any, item: IPrimeMenuItem, level: number): void => {
    const subLevel = level !== undefined && level >= 1;
    const hasChildren = e.target.classList.contains("has-children") && subLevel;

    // If item is open, close it and return
    if (item.open) {
      toggleDropdown(item.id, false, hasChildren);
      return;
    }

    // If there are no children for item, go to link and close all dropdowns
    if (!item.children || !showSubItems(item)) {
      onNavigate(item);
      closeAllDropdowns();
      return;
    }

    // If item clicked on has any children
    if (item.children || hasChildren) {
      e.preventDefault();
      if (!mobile && !hasChildren) {
        // Close all dropdowns if it's a parent item and we are not on mobile viewport
        closeAllDropdowns();
      }
    }

    // If we are on lower level than parent
    subLevel
      ? // Open necessary dropdown and close others
        handleSameLevelDropdowns(item)
      : // If we are on parent level, open the dropdown
        toggleDropdown(item.id, !item.open, hasChildren);
  };

  /**
   * Check if the current menu item is the "Home" item.
   * In this case we need to check if we are at the project root
   * (Either in the new landing page of legacy Pulse)
   * @param item Menu item to check
   */
  const isHome = (item: IPrimeMenuItem): boolean => {
    if (item.id !== 0) {
      return false;
    }
    const homeUrlList = [
      `${getConfig().primeEndpoint}`,
      `${getConfig().primeEndpoint}/`,
      `${getConfig().primeEndpoint}/#`,
      `${getConfig().primeEndpoint}/#/`,
      `${getConfig().primeEndpoint}/pulse/#`,
      `${getConfig().primeEndpoint}/pulse/#/`,
      `${getConfig().primePulseEndpoint}`,
      `${getConfig().primePulseEndpoint}/`,
      `${getConfig().primePulseEndpoint}/#`,
      `${getConfig().primePulseEndpoint}/#/`,
      `${getConfig().primePulseEndpoint}/pulse/#`,
      `${getConfig().primePulseEndpoint}/pulse/#/`,
    ];
    const locationUrl = window.location.href;
    return homeUrlList.some((url) => locationUrl.endsWith(url));
  };

  if (!items?.length) {
    return null;
  }

  return (
    <ul
      className={`pm-level-${level} pm-list`}
      data-name={parentItem ? parentItem.displayName : ""}
      onClick={(e) => {
        if (!(e.target instanceof HTMLAnchorElement)) {
          closeAllDropdowns();
        }
      }}
    >
      {items.map((item, i) => {
        const isOpenCssClass = item.open ? "is-open" : "";
        const hasChildrenCssClass =
          item.children && item.children.length && showSubItems(item)
            ? "has-children"
            : "";
        const isActiveClass =
          item.active === true || isHome(item) ? "is-active" : "";
        const subLevelClass =
          level !== undefined && level >= 1 ? "sub-level" : "";

        const rootUrl = isPulseUrl(item.url)
          ? getPrimePulseEndpoint()
          : getPrimeEndpoint();

        return (
          <li
            key={i}
            id={`pm-${item ? item.id : ""}`}
            className={`pm-level-${level}-item ${isOpenCssClass} ${hasChildrenCssClass} ${isActiveClass} ${subLevelClass}`}
          >
            <a
              href={makeRelativeUrlAbsolute(item.url, rootUrl)}
              onClick={(e) => {
                handleClick(e, item, level);
                if ((isActiveClass && !hasChildrenCssClass) || item.open) {
                  e.preventDefault();
                  return;
                }
              }}
              className={`d-flex justify-content-between align-items-center ${isOpenCssClass} ${hasChildrenCssClass}`}
            >
              {item.displayName}
              <Arrow
                className="pm-arrow"
                isVisible={item.children && showSubItems(item) ? true : false}
                showCaret={level === 0 || mobile}
                itemId={item.id}
              />
            </a>

            {showSubItems(item) ? (
              <Menu
                parentItem={item}
                items={item.children || []}
                level={level + 1}
                mobile={mobile}
                toggleDropdown={toggleDropdown}
                closeAllDropdowns={closeAllDropdowns}
                handleSameLevelDropdowns={handleSameLevelDropdowns}
                onNavigate={onNavigate}
              />
            ) : null}
          </li>
        );
      })}
    </ul>
  );
};

export default Menu;
