/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from "react";

const useHasChanged = (val: any): boolean => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

const usePrevious = (value: any): any => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default useHasChanged;
