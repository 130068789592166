/**
 * Validate if the provided URL is external or not.
 * Credit to: https://stackoverflow.com/a/6238456
 * @param url The url to validate.
 */
export function isExternal(url: string): boolean {
  // Credit to: https://stackoverflow.com/a/6238456
  const match: RegExpMatchArray | null = url.match(
    // eslint-disable-next-line no-useless-escape
    /^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/
  );

  if (!match) {
    return false;
  }

  if (
    typeof match[1] === "string" &&
    match[1].length > 0 &&
    match[1].toLowerCase() !== location.protocol
  ) {
    return true;
  }
  if (
    typeof match[2] === "string" &&
    match[2].length > 0 &&
    match[2].replace(
      new RegExp(
        ":(" +
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (({ "http:": 80, "https:": 443 } as any)[location.protocol] as any) +
          ")?$"
      ),
      ""
    ) !== location.host
  ) {
    return true;
  }
  return false;
}

export function isAbsolute(url: string): boolean {
  const r = new RegExp("^(?:[a-z]+:)?//", "i");
  return r.test(url);
}

export function isPulseUrl(url: string): boolean {
  // Pulse URLs starts with `pulse` or `/pulse`
  return (
    url.toLocaleLowerCase().startsWith("pulse") ||
    url.toLocaleLowerCase().startsWith("/pulse")
  );
}

/**
 * Make a relative URL absolute.
 * @param url - The url to transform
 * @param rootUrl - The base URL. For example: https://www.sg.com/.
 * @param fallbackUrl  - The fallbackUrl if no URL is provided or empty.
 */
export function makeRelativeUrlAbsolute(
  url: string,
  rootUrl: string,
  fallbackUrl = "#"
): string {
  // If no URL is provided, return the fallbackUrl.
  if (!url) {
    return fallbackUrl;
  }

  // Validate if the url is external
  if (!isExternal(url)) {
    // If the internal link is absolute, return it as-is
    if (isAbsolute(url)) {
      return url;
    }
    // If the internal link starts with `/`, simply return `rootUrl` + `url`
    if (url.startsWith("/")) {
      return rootUrl + url;
    } else {
      // If the internal link doesn't start with `/`, return `rootUrl` + "/" + `url`.
      return rootUrl + "/" + url;
    }
  }

  return url;
}

/**
 * Remove any hashes from the URL.
 * Needed for reseting the state when testing location changes
 */
export function removeHashFromUrl(): void {
  const uri = window.location.toString();
  if (uri.indexOf("#") > 0) {
    const cleanUri = uri.substring(0, uri.indexOf("#"));
    window.history.replaceState({}, document.title, cleanUri);
  }
}
/**
 * Assign user to a new URL location
 * @param url The url to assign.
 */
export function locationAssign(url: string): void {
  window.location.assign(url);
}
