import PrimeMenuComponent from "./components/PrimeMenu";
import React from "react";
import WithCss from "../WithCss";
import { widgetize, WidgetPropsMapping } from "@sg-widgets/react-core";
import * as css from "./prime-menu.less";

export interface IPrimeMenuProps {
  breakpoint?: string;
  truncateOffset?: string;
}

const PrimeMenu: React.FC<IPrimeMenuProps> = ({
  breakpoint,
  truncateOffset,
}) => {
  return (
    <WithCss styles={css}>
      <PrimeMenuComponent
        breakpoint={breakpoint ? parseInt(breakpoint, 10) : 1024}
        truncateOffset={truncateOffset ? parseInt(truncateOffset, 10) : 50}
      />
    </WithCss>
  );
};

widgetize("prime-menu", PrimeMenu, {
  breakpoint: WidgetPropsMapping.asString({
    description: "The breakpoint",
  }),
  truncateOffset: WidgetPropsMapping.asString({
    description: "The truncate offset",
  }),
});
