import { excludeSubsections } from "../../config";
import IPrimeApiItem from "../models/IPrimeApiItem";
import IPrimeMenuItem from "../models/IPrimeMenuItem";

export const convertToPrimeMenuItem = (item: IPrimeApiItem): IPrimeMenuItem => {
  /**
   * If this is an item from the list of excluding subsections,
   * we should map its url to the first child url
   */
  if (
    excludeSubsections.filter((name) => item.name === name).length > 0 &&
    item.children &&
    item.children.length > 0
  ) {
    return {
      active: false,
      children: item.children.map((child) => convertToPrimeMenuItem(child)),
      displayName: item.displayName,
      hoverDescription: item.hoverDescription,
      id: item.id,
      name: item.name,
      open: false,
      parentIds: [],
      url: item.children[0].url ?? "#",
    };
  }
  /**
   * Map item normally
   */
  return {
    active: false,
    children: item.children
      ? item.children.map((child) => convertToPrimeMenuItem(child))
      : undefined,
    displayName: item.displayName,
    hoverDescription: item.hoverDescription,
    id: item.id,
    name: item.name,
    open: false,
    parentIds: [],
    url: item.url ?? "#",
  };
};

export const convertAllToPrimeMenuItem = (
  items: IPrimeApiItem[]
): IPrimeMenuItem[] => {
  return items.map((item) => convertToPrimeMenuItem(item));
};
