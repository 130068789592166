import React from "react";
/**
 * Props interface.
 */
interface Props {
  isVisible: boolean;
  showCaret: boolean;
  itemId?: number;
  className?: string;
}

/**
 * The arrow used on mobile / desktop to indicate that there is a dropdown menu.
 * @param props - Component props.
 */
const Arrow: React.FC<Props> = ({ isVisible = true, showCaret }) => {
  if (!isVisible) {
    return null;
  }
  if (showCaret) {
    return <span className="caret"></span>;
  }
  return <span className="caret right-side"></span>;
};

export default Arrow;
